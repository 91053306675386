import BannerHome from "@components/Banner/BannerHome"
import ContentAndStats from "@components/ContentAndStats/ContentAndStats"
import ImagesSlider from "@components/ImagesSlider/ImagesSlider"
import Layout from "@components/Layout/Layout"
import loadable from "@loadable/component"
import OfficeListing from "@components/OfficeListing/OfficeListing"
import OfficeLocation from "@components/OfficeLocation/OfficeLocation"
import OurServices from "@components/OurServices/OurServices"
import ReviewsSlider from "@components/ReviewsSlider/ReviewsSlider"
import TileBlock from "@components/TileBlock/TileBlock"
import Timeline from "@components/Timeline/Timeline"
import { VideoBannerModule } from "@components/VideoBanner/VideoBanner"
import Seo from "@components/seo"
import "@styles/main.scss"
import { graphql } from "gatsby"
import React, { useEffect } from "react"
import ReviewList from "@components/ReviewList/ReviewList"
import FormModule from "@components/ContactFomModule/FormModule"
import BannerAbout from "@components/Banner/BannerAbout"
const VideoAskWidget = loadable(() => import("@components/VideoAsk/VideoAsk"))
const Landing2 = ({ data }) => {
  const pageData = data?.strapiPage
  const menuData = pageData?.menu
  const modules = pageData?.modules
  const banner = pageData?.banner
  const imagetransforms = pageData?.imagetransforms
  const strapiID = pageData?.strapi_id
  useEffect(() => {
    if (sessionStorage.getItem("searchpath") && typeof window !== "undefined") {
      sessionStorage.removeItem("searchpath");
    }
  }, []);
  return (
    <Layout pageData={pageData}>
      <BannerAbout
        bannerData={banner}
        ggfx_results={pageData?.ggfx_results}
        strapiID={strapiID}
        menuData={menuData}
        centerContent
      />
            {pageData?.video_ask_url &&
<VideoAskWidget video_ask_url={pageData?.video_ask_url} />
}
      {modules?.map((module) => (
        <React.Fragment key={module.id}>
          {module?.strapi_component === "modules.content-and-stats" && (
            <ContentAndStats module={module} />
          )}
           {module?.strapi_component === "modules.images-slider" && (
            <ImagesSlider
              module={module}
              ggfx_results={pageData?.ggfx_results}
              strapiID={strapiID}
            />
          )}
           {module?.strapi_component === "components.video-block" && (
            <VideoBannerModule
              module={module}
              ggfx_results={pageData?.ggfx_results}
              strapiID={strapiID}
            />
          )}
          {module?.strapi_component === "modules.timeline" &&
            module?.numbered_timeline && <Timeline module={module} />}
          {module?.strapi_component === "modules.office-location" && (
            <OfficeLocation module={module} />
          )}
          {module?.strapi_component === "modules.listing-module" &&
            module.module === "office_listing" && <OfficeListing />}
          {module?.strapi_component === "modules.video-reviews" && (
            <ReviewsSlider module={module} />
          )}
          {module?.strapi_component === "modules.global-module" &&
            module.choose_module === "reviews_slider" && (
              <ReviewList module={module} reviews={pageData?.reviews} />
            )}
             {module?.strapi_component === "modules.form-module" && (
            <FormModule module={module} />
          )}
            {module?.strapi_component === "modules.tile-block" && (
            <TileBlock
              module={module}
              ggfx_results={pageData?.ggfx_results}
              strapiID={strapiID}
            />
          )}
          {module?.strapi_component === "modules.our-services" && (
            <OurServices
              module={module}
              ggfx_results={pageData?.ggfx_results}
              strapiID={strapiID}
            />
          )}
        </React.Fragment>
      ))}
    </Layout>
  )
}

export default Landing2

export const Head = ({ data }) => {
  const pageData = data?.strapiPage

  return (
    <Seo
      title={pageData?.seo?.metaTitle}
      description={pageData?.seo?.metaDescription}
      customCanonical={pageData?.seo?.canonicalURL}
    />
  )
}

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...pageFragment
      modules {
        ... on STRAPI__COMPONENT_MODULES_CONTENT_AND_STATS {
          ...contentAndStats
        }
        ... on STRAPI__COMPONENT_MODULES_TIMELINE {
          ...timelineFragment
        }
        ... on STRAPI__COMPONENT_MODULES_IMAGES_SLIDER {
          ...imgSlider
        }
        ... on STRAPI__COMPONENT_MODULES_GLOBAL_MODULE {
          ...globalModule
        }
        ... on STRAPI__COMPONENT_MODULES_OUR_SERVICES {
          ...ourServices
        }
        ... on STRAPI__COMPONENT_MODULES_LISTING_MODULE {
          id
          module
          strapi_component
        }
        ... on STRAPI__COMPONENT_MODULES_OFFICE_LOCATION {
          ...officeLocation
        }
        ... on STRAPI__COMPONENT_MODULES_VIDEO_REVIEWS {
          ...videoReviews
        }
        ... on STRAPI__COMPONENT_MODULES_GLOBAL_MODULE {
          ...globalModule
        }
        ... on STRAPI__COMPONENT_MODULES_TILE_BLOCK {
          ...tileBlock
        }
        ... on STRAPI__COMPONENT_MODULES_FORM_MODULE {
          ...FormModuleFragment
        }
        ... on STRAPI__COMPONENT_COMPONENTS_VIDEO_BLOCK {
          ...videoBlockFragment
        }
      }
    }
  }
`
